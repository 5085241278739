<template>
  <BaseDialog
    :show="true"
    title="編輯基本設定"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem prop="isShowIndustry" label="產業類別是否顯示於 C 端">
          <BaseElSwitch
            v-model="formData.isShowIndustry"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>
        <BaseElFormItem prop="homeMode" label="預約內容頁面">
          <span class="flex text-sm leading-[17.38px] my-[8px]">如果您需要輪播或是店面文字介紹，可以選擇完整內容版。</span>
          <BaseElSelect v-model="formData.homeMode">
            <BaseElSelectOption
              v-for="item in homeModeConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { homeModeConfig } from '@/config/pubApt'
import { get } from 'lodash'

export default defineComponent({
  name: 'BasicSettingEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
    onSubmit: { type: Function, default: () => {} },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, onSubmit, loading } = useBaseForm()

    initFormData({
      isShowIndustry: get(props, 'configData.isShowIndustry'),
      homeMode: get(props, 'configData.homeMode'),
    })

    const formRules = {
      homeMode: [noEmptyRules()],
    }

    const onConfirm = async () => {
      await onSubmit(props.onSubmit, formData, {
        emit,
        loading,
      })
    }
    return { formRef, formData, formRules, onConfirm, homeModeConfig }
  },
})
</script>

<style lang="postcss" scoped></style>
